<template>
  <div>
    <!-- <van-nav-bar class="backBox" title="停车场" /> -->
    <div class="home_box">
      <div class="blue-background"></div>
      <div class="white-background"></div>
      <div class="home_content_box">
        <!-- 2222 -->
        <header>
          <div class="parkSiteImg">
            <img v-if="park.avatar" class="park-pic" :src="park.avatar" />
            <div
              v-else
              style="
                background: lightblue;
                font-size: 0.4rem;
                text-align: center;
                line-height: 0.64rem;
              "
              class="park-pic"
            >
              {{ park.parkname[0] }}
            </div>
          </div>
          <div class="parkSiteText">
            <div>
              <span>{{ park.parkname }}</span>
            </div>
            <div>
              <span>车位数：{{ park.total_spaces }}</span>
            </div>
            <!-- <div v-if="park.feeRule">
              <span >收费规则：{{ park.feeRule / 100 }}元/小时</span>
            </div> -->
          </div>
        </header>
        <main>

          <!-- <div class="plate_num_box">
          <h3>请输入车牌号</h3>

          </div> -->
          <div class="plate_num_box">
            <div class="plate_num_box_tit" style="text-align: left">
              请输入车牌号
            </div>
            <div class="plate_num_box_input">
              <div
                class="plate_wrap"
                v-for="(item, index) in plateNumber"
                :key="index"
                @click.stop="bindFocus(index)"
              >
                <div
                  class="plate_wrap_input"
                  v-if="index !== 7"
                  :class="[input_index === index ? ' section-dashed' : '']"
                >
                  {{ item }}
                </div>
                <!-- 2 -->
                <div
                  class="plate_wrap_input new_input"
                  v-if="index === 7"
                  :class="[input_index == index ? ' section-dashed' : '']"
                >
                  <div v-if="newFlag" placeholder="">
                    {{ item }}
                  </div>
                  <img
                    v-if="!newFlag"
                    src="../assets/img/indexnewnengyuan@2x.png"
                    alt=""
                  />

                  <span v-if="!newFlag" class="new_vh">新能源</span>
                </div>
              </div>
            </div>

            <div class="history">
              <!-- 图标 -->

              <span
                class="historyIcon"
                v-show="historyNumList && historyNumList.length > 0"
              >
                <van-icon class="iconfont icon-lishi" />
              </span>
              <div
                class="historyItem"
                v-for="item in historyNumList"
                :key="item.id"
                data-info="item"
                @click="clickHistoryNum(item)"
              >
                {{ item }}
              </div>
            </div>
            <van-button @click="doQuery" class="query_btn">
              <span class="query_btn_text">查询停车费</span>
            </van-button>
          </div>
          <div class="menuFooterBox">
            <div class="common_box menu_box" v-if="userAgent === 'wx'">
              <div
                class="menu_wrap"
                v-for="item in menuList"
                :key="item.id"
                @click="openMenu(item)"
              >
                <div
                  class="menu_img"
                  :style="{
                    background:
                      'linear-gradient(to bottom, ' +
                      item.backTopColor +
                      ' 0%, ' +
                      item.backBotColor +
                      ' 100%)',
                    fontSize: item.fontSize, // 添加字体大小样式
                  }"
                >
                  <van-icon :class="['iconfont', item.src]" />
                </div>
                <span class="menu_title">{{ item.title }}</span>
              </div>
            </div>
            <!-- 商户入口merchant -->
            <!-- <div class="merchant_box"> -->
            <!-- <div class="menu_box" @click="openMerchant">
              <div
                class="menu_img menu_shanghu"

              >
                <van-icon class="iconfont icon--shanghu-copy" />
              </div>
              <span class="menu_title">商家入口</span>
            </div> -->
            <!-- <div class="menu_box" @click="openMyBill">
              <div
                class="menu_img menu_zhangdan"

              >
                <van-icon class="iconfont icon-tianchongxing-" />
              </div>
              <span class="menu_title">我的账单</span>
            </div> -->
            <!-- </div> -->
            <!-- 广告位置 -->
            <footer class="common_box footer" v-if="!isCloseAD">
              <!-- <div class="AD">广告</div> -->
              <div class="banner" style="">
                <adver urls="501008286821191680"></adver>
              </div>
            </footer>
          </div>
        </main>
      </div>
    </div>
    <!-- 键盘 -->
    <car-keyboard
      ref="keyboard"
      :show.sync="showKeyboard"
      @getResult="setNumber"
      :plateNumber.sync="plateNumber"
      :index.sync="input_index"
      @setIndex="setIndex"
    ></car-keyboard>

    <!-- 绑定手机 -->
    <bind-phone
      :show="show_bind_phone"
      @get-success="getBindPhoneSuccess"
      @get-on-close="getBindPhoneShow"
    ></bind-phone>

    <!-- 未授权或者未绑定手机的提示弹窗 -->
    <not-auth-or-phone
      :show="showNotAuthPhone"
      :show-type="showNotAuthPhoneType"
      :want-to-path="wantToPath"
      :park-name="park.parkname"
      @get-on-close="getNotAuthBindPhoneShow"
      @showBindPhone="showBindPhone"
    ></not-auth-or-phone>
  </div>
</template>
<script>
import axios from 'axios'
import * as mqtt from '../api/mqtt/wsconnect.js'
import CarKeyboard from '../components/CarKeyboard.vue'
import { Toast } from 'vant'
import { mapState, mapGetters } from 'vuex'
import * as constant from '../api/constant'
import * as common from '../api/common'
import * as util from '../api/util'
import * as api from '../api/api'
import BindPhone from '../components/BindPhone.vue'
import NotAuthOrPhone from '../components/NotAuthOrPhone.vue'
import Adver from '../components/Adver.vue'

export default {
  name: 'UserHome',
  components: { CarKeyboard, BindPhone, NotAuthOrPhone, Adver },
  data () {
    return {
      park: {
        total_spaces: 1,
        parkname: '车场',
        avatar: '',
        feeRule: 500
      },
      menuList: [
        {
          id: 0,
          // src: require('../assets/img/indexyuezubanli@2x.png'),
          src: 'icon-yuezuche',
          title: '月租办理',
          backTopColor: '#4897FF',
          backBotColor: '#125EFF',
          path: '/monthlyRental/monthlyRentLong',
          fontSize: '0.2rem'
        },
        {
          id: 1,
          // src: require('../assets/img/indexyuezubanli@2x.png'),
          src: 'icon-yanqi',
          title: '延期/续租',
          backTopColor: '#FF6DAD',
          backBotColor: '#FF006B',
          path: '/reletPostpone',
          fontSize: '0.2rem'
        },
        {
          id: 2,
          src: 'icon-youhuiquan2',

          title: '我的优惠券',
          backTopColor: '#FFB300',
          backBotColor: '#FF8A00',
          path: '/myCoupon',
          fontSize: '0.2rem'
        },
        {
          id: 3,
          src: 'icon--tingche',
          title: '我的车辆',
          backTopColor: '#4CAF50',
          backBotColor: '#2196F3',
          path: '/myCar',
          fontSize: '0.2rem'
        },
        {
          id: 4,
          src: 'icon-kaifapiaoxitong',
          title: '电子发票',
          backTopColor: '#A790F7',
          backBotColor: '#5C2BED',
          path: '/invoice/index',
          fontSize: '0.2rem'
        },
        {
          id: 5,
          src: 'icon-tingchejilu',
          title: '停车记录',
          backTopColor: '#00FFEA',
          backBotColor: '#00DDCF',
          path: '/parkingRecord',
          fontSize: '0.2rem'

        },
        {
          id: 6,
          src: 'icon-tianchongxing-',
          title: '我的账单',
          backTopColor: '#b3b8d5',
          backBotColor: '#2B6BED',
          path: '/myBill',
          fontSize: '0.2rem'
        },
        {
          id: 7,
          src: 'icon-wodefuwu-copy',
          title: '我的服务',
          backTopColor: '#31ab9c',
          backBotColor: '#0d3bb5',
          path: '/monthlyRental/myService',
          fontSize: '0.2rem'
        },
        {
          id: 8,
          src: 'icon-icon-test',
          title: '我的',
          backTopColor: '#90F7EA',
          backBotColor: '#2B6BED',
          path: '/user/my',
          fontSize: '0.2rem'
        }
      ],
      plateNumber: ['', '', '', '', '', '', '', ''], // 键盘操作结果值
      plateNumberBak: ['', '', '', '', '', '', '', ''],
      input_index: 0,
      historyNumList: [],
      historyFlag: false,
      newFlag: false,
      newNum: '',
      car_keyboard_show: false,
      showKeyboard: false,
      textValue: '', // 最终的输入内容
      keyBoardType: 1, // 控制键盘显示类型,1为省份简写,2为英文简写
      numBoard: false, // 控制  是否显示 英文简写中的数字部分键盘  true 为显示
      isShow: false, // 控制键盘是否显示,true显示，false不显示
      array: ['浙A11111', '浙B12345', '浙C12345', '浙D12345', '浙E12345'],
      index: '',
      closeOnClick: false,
      giveFlag: false,
      show_bind_phone: false,
      showNotAuthPhone: false,
      showNotAuthPhoneType: null,
      qryCarNum: '',
      pnulloutvisible: false, // 是否无牌车；true是无牌车；默认false
      pnullvisible: false,
      appidLocal: '',
      userAgent: 'wx',
      isCloseAD: false, // 是否关闭当前页面广告
      wantToPath: ''

    }
  },
  mounted () {
    // 如果是应用第一次启动 则判断sid和channel_path是否存在；如果是从router中的页面返回，判断不存在时主动赋值即可。
    console.log('mounted query', this.$route.query)
    const isFirstLoad = !sessionStorage.getItem('hasLoadedBefore')
    if (isFirstLoad) {
      sessionStorage.setItem('hasLoadedBefore', 'true')
      if (!this.$route.query.sid || !this.$route.query.channel_path) {
        console.error('Error: sid or channel_path is missing.')
        this.$dialog.alert({
          title: '提示',
          message: '车场编码丢失，请检查URL'
        }).then(() => {
          this.doCloseAll()
        })
      }
    } else {
      if (!this.$route.query.sid) {
        this.$route.query.sid = this.sid
      }
      if (!this.$route.query.channel_path) {
        this.$route.query.channel_path = -1
      }
    }
    document.addEventListener('click', this.handleClickOutside)
    // 初始 欠费数据重置为null
    this.$store.commit(constant.keyEscapeVuex, null)
    this.getWxOrZfb()
    this.queryADConfig()
    const roleType = 1
    this.$store.commit(constant.keyAccountRoleVuex, roleType)
    if (!this.$route.query.sid && !this.sid) {
      this.doCloseAll()
    }
    // const testSid = 'yuen01'
    // const testSid = 'ynwb01'
    // const testSid = 'YX45SX'
    // if (!this.sid) {
    this.$store.commit(constant.keySidVuex, this.$route.query.sid)
    // }
    var pathID =
      this.$route.query.sid +
      common.format(new Date(), 'yyyyMMddhhmmss') +
      (Math.floor(Math.random() * (9999 - 1000)) + 1000)
    if (this.$route.query.channel_path) {
      if (this.$route.query.channel_path === -1) {
        this.$store.commit(constant.keyChannelPathIDVuex, pathID)
      }
      this.$store.commit(constant.keyChannelPathVuex, this.$route.query.channel_path)
    } else {
      this.$store.commit(constant.keyChannelPathIDVuex, pathID)
      this.$store.commit(constant.keyChannelPathVuex, '-1')
    }
    if (this.queryLogHistory) {
      this.historyNumList = this.queryLogHistory
    }
    this.doInitQuery().catch((e) => console.log(e))
    this.query_customer_by_sid()
    // 获取当前车场支付平台
    this.getPayType()
    // 需要授权登录且绑定手机号才可访问
    const authFlag = util.isHaveAuthAccount()
    if (authFlag) {
      // TODO 如果已授权 则主动获取手机号登个人信息且保存
      this.getInfo()
    }
    // TODO 增加入场缴费

    // this.getAppId()
  },
  computed: {
    ...mapState({
      authPhone: state => state.phone.phone,
      channelPathID: state => state.channelPathID.channelPathID,
      channelPath: state => state.channelPath.channelPath,
      sid: state => state.sid.sid,
      queryLogHistory: state => state.queryLogHistory.queryLogHistory
    }),
    ...mapGetters(['openID'])

  },
  watch: {
    // 监听 authPhone 属性的变化
    authPhone (newValue, oldValue) {
      // 监听到手机号已绑定
      if (this.show_bind_phone) {
        this.show_bind_phone = false
      }
      if (this.showNotAuthPhone) {
        this.showNotAuthPhone = false
      }
    }
  },

  methods: {
    // TODO
    async getInfo () {
      try {
        const { data } = await axios({
          url: common.cloudPayUrl + '/pub/' + api.urlList.pay.bill.park_plate.get_self_info,
          method: 'POST',
          data: {
            sid: this.sid || this.$route.query.sid,
            // sid: this.sidLocal,
            openid: this.openID
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })

        if (data.code === 10000 && data.info) {
          // this.authPhoneLocal = data.phone_number
          this.$store.commit(constant.keyPhoneVuex, data.info.phone_number)
        } else {
          Toast.fail('查询出错', data.msg)
        }
      } catch (e) {
        this.$toast('查询异常', e)
      }
    },
    async getPayType () {
      try {
        const sidNow = this.$route.query.sid || this.sid
        if (sidNow) {
          const thePayType = await util.getPayTypeBySid(sidNow)
          console.log('thePayType: ', thePayType)
          this.$store.commit(constant.keyPayTypeVuex, thePayType)
        }
      } catch (error) {
        console.error('Error get pay type:', error)
      }
    },
    handleClickOutside (event) {
      const keyboardElement = this.$refs.keyboard.$el
      if (keyboardElement && !keyboardElement.contains(event.target)) {
        if (this.showKeyboard) {
          this.showKeyboard = false
        }
      }
    },
    // 获取关闭广告配置
    async queryADConfig () {
      try {
        const sid = this.$route.query.sid
        if (sid) {
          const adConfig = await util.queryADConfig(sid)
          const flag = util.judgeADClose(adConfig, 1, 2)
          this.isCloseAD = flag
        }
      } catch (e) {
        console.log('error', e)
        const firstConfig = localStorage.getItem('adConfig')
        if (firstConfig) {
          const adConfig = JSON.parse(firstConfig)
          const flag = util.judgeADClose(adConfig, 1, 2)
          this.isCloseAD = flag
        } else {
          this.isCloseAD = false
        }
      }
    },
    getWxOrZfb () {
      if (common.getUserAgent() === 'wx') {
        this.userAgent = 'wx'
      } else if (common.getUserAgent() === 'zfb') {
        this.userAgent = 'zfb'
      }
    },
    async getAppId () {
      const res = await axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_wx_appid,
        method: 'POST',
        data: {
          sid: this.sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      })
      const d = res && res.data
      if (d.code === 10000) {
        this.appidLocal = d.app_id
        this.$store.commit(constant.keyAppIDVuex, d.app_id)
        return this.appidLocal
      } else {
        return Promise.reject(new Error())
      }
    },
    // getAppId () {
    //   return axios({
    //     url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_wx_appid,
    //     method: 'POST',
    //     data: {
    //       sid: this.sid
    //     },
    //     header: {
    //       'content-type': 'application/json' // 默认值
    //     }
    //   }).then(res => {
    //     const d = res && res.data
    //     if (d.code === 10000) {
    //       this.appid = d.app_id
    //       return this.appid
    //     } else {
    //       return Promise.reject(new Error())
    //     }
    //   })
    // },
    doInitQuery: async function () {
      try {
        const client = await this.init_mqtt()
        client.callback.get_pnumber_by_out_channel_id =
          this.get_pnumber_by_out_channel_id
        if (client && client.connected) {
          client.callback.fq = this.queryFQResult
          const topic = `/ypt/u_${this.sid}/${this.channelPath}`
          const options = {
            payload: {
              topic: 'get_pnumber_by_out_channel_id',
              channel_path: this.channelPath || -1
            }
          }
          await mqtt.subscribe(topic, options, function (err, grant) {
            console.log('grant', grant, 'err', err)
          })
          // if (this.$Storejs.getItem('vh_p_number') && Number(this.$route.query.channel_path) > 0
          // // && this.$route.query.direction == util.direction.out
          // ) {
          //   if (sessionStorage.getItem('plate_number')) {
          //     sessionStorage.removeItem('plate_number')
          //     return
          //   }
          //   await this.queryData()
          //
          // }
          if (Number(this.$route.query.channel_path) > 0) {
            try {
              // debugger
              await this.getPnumberByOutChannelId()
            } catch (e) {
              this.$toast.fail((e && e.msg) || '获取车牌失败')
            }
          } else {
            this.autoOnsiteQuery()
          }
        }
      } catch (e) {
        console.log('doInitQuery catch', e)
        // this.$dialog.alert({
        //   title: '提示',
        //   message: JSON.stringify(e)
        // }).then(() => {
        // })
        this.$dialog.alert({
          title: '提示',
          message: '网络异常,点击确认后重试'
        }).then(() => {
          // TODO 开发环境暂时关闭
          this.doCloseAll()
        })
      }
    },
    autoOnsiteQuery () {
      // 判断地址是否带车牌号和是否是场内付
      if (!this.$route.query.plate_number || Number(this.$route.query.channel_path) > 0) {
        return
      }
      // 填入车牌号
      this.refreshPlateNumberFromChannel({
        vh_p_number: this.$route.query.plate_number
      })
      // 清除地址上的车牌参数，防止死循环
      const par = JSON.parse(JSON.stringify(this.$route.query))
      par.plate_number = null
      const url_params = util.objSortJoin(par)
      let url = util.getIndexUrl()
      if (url_params) {
        url += '?' + url_params
      }
      window.location.replace(url)
      this.queryData()
    },
    async getPnumberByOutChannelId () {
      const that = this
      if (Number(that.$route.query.channel_path) > 0) {
        let res
        try {
          res = await axios({
            url: common.cloudParkingUrl +
              '/s2p/' +
              api.urlList.get_pnumber_by_out_channel_id,
            method: 'POST',
            data: {
              sid: that.sid,
              channel_path: Number(that.$route.query.channel_path),
              version: 'v2'
            },
            header: {
              'content-type': 'application/json' // 默认值
            }
          })
        } catch (e) {
          that.$toast.fail('获取车牌出错')
          await Promise.reject(e)
        }
        let data
        if (res && res.status >= 200 && res.status < 300) {
          data = res.data
          console.log('has channel_path-->', data)
          if (data.code === common.RespMsg.OK.code) {
            try {
              if (!data.version || data.version === 'v1') {
                this.refreshPlateNumberFromChannel(data)
                // if (localStorage.getItem('vh_p_number')) {
                //   localStorage.removeItem('vh_p_number')
                //   return
                // }
              }
            } catch (e) {
              console.log(e)
            }
          } else {
            // eslint-disable-next-line no-throw-literal
            throw {
              msg: '获取车牌失败'
            }
          }
        }
        return data
      }
    },
    async init_mqtt (cb) {
      var that = this
      // var utils = util
      const config = {
        onConnected: function () {
          // console.log('connecteding')
        },
        onReconnect: that.subscribe,
        onMessage: this.onMessage
      }
      const client = await mqtt.getClient(config)
      if (client && client.connected) {
        typeof cb === 'function' && cb(client)
        return client
      } else {
        this.$toast.fail('NetWork err!')
      }
    },
    onMessage: function (topic, message, packet) {
      const that = this
      // const utils = util
      message = JSON.parse(message.toString()) // 收到的信息
      console.log('onMessage', topic, message, packet)
      if (message && message.code !== common.resCode.OK) {
        if (/^N.{7}/.test(decodeURIComponent(message.plate_number))) {
          that.vh_p_n_in_msg = common.resMSG[message.code]
          that.pnullvisible = true
        }
        that.$toast(message.msg || '查询出错了')
        return
      }
      if (message.vh_state === 1) {
        if (message.vh_cate_id === 1) {
          return that.$toast('月租车, 收费 0 元')
        }
        if (message.vh_cate_id === 3) {
          return that.$toast('储值车')
        }
      }
      const plateNumber = that.qryCarNum // 输入的车牌
      if (decodeURIComponent(message.plate_number) === plateNumber) {
        Toast.clear()
        if (message.topic === 'tz') {
          if (message.state === 2) {
            // localStorage.removeItem('vh_p_number')
            this.$store.commit(constant.keyPlateNumberVuex, null)
            Toast.success('支付成功')
          } else {
            Toast.success('支付完成')
          }
          that.$router.go(-1)
          return
        }
        if (isNaN(Number(message.fee_value)) || message.fee_value == null) {
          return Toast.fail(message.msg || '计算费用失败')
        }
        const localInfo = JSON.stringify(message)

        if (Number(message.fee_value) <= 0) {
          Toast('收费 0 元')
          if (message.vh_cate_id !== 1) {
            // 查询成功后跳转到支付页面
            // 需要传入escInfo
            this.$store.commit(constant.keyEscapeVuex, message.escInfo)

            that.$router.push({
              path: '/payMoney',
              query: {
                plate_number: plateNumber,
                gross_price: message.gross_price,
                req_order_sn: message.req_order_sn,
                start_time: message.start_time,
                end_time: message.end_time,
                sign: message.sign,
                localInfo: localInfo,
                pay_channel_platform: message.pay_channel_platform,
                receive_timestamp: new Date().getTime()
              }
            })
          }

          const channelPath = that.channelPath

          if (
            channelPath > 0 &&
            !message.is_done_open &&
            /^N.{7}/.test(decodeURIComponent(message.plate_number))
          ) {
            that.open_gate_0_fee({
              sid: this.sid,
              plate_number: message.plate_number,
              fee_value: message.fee_value,
              channel_path: channelPath,
              is_done_open: false
            })
          }
          return
        }
        this.$store.commit(constant.keyEscapeVuex, message.escInfo)
        that.$router.push({
          path: '/payMoney',
          query: {
            plate_number: plateNumber,
            gross_price: message.gross_price,
            req_order_sn: message.req_order_sn,
            start_time: message.start_time,
            end_time: message.end_time,
            sign: message.sign,
            localInfo: localInfo,
            pay_channel_platform: message.pay_channel_platform,
            receive_timestamp: new Date().getTime()
          }
        })
      }
    },
    // 根据sid查询车场信息
    query_customer_by_sid () {
      // this.$Storejs.setItem('sid')
      axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_customer,
        method: 'POST',
        data: {
          sid: this.sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then((res) => {
        const d = res && res.data
        if (d.code === 10000) {
          this.park = {
            total_spaces: d.list[0].mix_info.total_spaces || 100,
            parkname: d.list[0].alias_name || '车场',
            avatar: d.list[0].avatar,
            // ToDo 默认是5元/小时
            feeRule: d.list[0].feeRule || 500
          }
          // 保存车场名称
          localStorage.setItem(constant.keyParkNameLocalStorage, this.park.parkname)

          // resolve(payargs)
        } else {
          // reject();
        }
      })
    },
    // openMerchant () {
    //   const flag = this.checkAuthWhenOpenMenu()
    //   if (flag) {
    //     this.$router.push('/business/home')
    //   }
    // },
    openMyBill () {
      // const flag = this.checkAuthWhenOpenMenu()
      // if (flag) {
      this.$router.push('/myBill')
      // }
    },
    showBindPhone () {
      this.show_bind_phone = true
    },
    getNotAuthBindPhoneShow () {
      this.showNotAuthPhone = false
    },
    getBindPhoneSuccess () {

    },
    getBindPhoneShow () {
      this.show_bind_phone = false
    },
    doQuery () {
      const carNum = this.plateNumber.join('')
      if (carNum.length === 7 || carNum.length === 8) {
        const arrOld = this.historyNumList
        const arrNew = []
        arrNew.push(carNum, ...arrOld)
        // 去重
        const qcArr = [...new Set(arrNew)]
        // 最大四个
        if (qcArr.length === 5) {
          qcArr.splice(qcArr.length - 1, 1)
        }
        this.historyNumList = qcArr
        // 保存vuex
        this.$store.commit(constant.keyQueryLogHistoryVuex, this.historyNumList)

        // 发起请求
        this.qryCarNum = carNum
        this.queryData()
      } else {
        Toast.fail('请输入正确的车牌查询')
      }
    },
    // 查询车牌
    async queryData () {
      // 查询车牌订单
      var that = this
      // 判断无牌车
      if (
        /^N/.test(this.qryCarNum) &&
        (Number(this.$route.query.channel_path) === -1 ||
          !this.$route.query.channel_path)
      ) {
        this.pnulloutvisible = true
      }

      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
      })
      const client = await this.init_mqtt()
      client.callback.fq = this.queryFQResult
      client.callback.tz = this.getTZ
      client.callback.mq_remote_open_gate = this.get_pnull_0_paid

      await this.subscribe('sq')
      const coupons = await this.get_coupon(this.qryCarNum)
      let couponID
      if (coupons && coupons.data && coupons.data.code === 10000) {
        if (Array.isArray(coupons.data.list) && coupons.data.list.length) {
          couponID = coupons.data.list[0].coupon_id
        }
      }
      const res = await that.sendFeeHttpRequest(this.qryCarNum, couponID)
      if (res && res.status >= 200 && res.status < 300) {
        const data = res.data
        if (data.code !== common.resCode.OK) {
          Toast.fail(common.resMSG[data.code] || '查询费用出错')
        }
      }
    },
    subscribe: async function (action, callback) {
      // 订阅
      const topic = `/ypt/u_${this.sid}/${encodeURIComponent(
        this.qryCarNum
      )}`
      const options = {
        payload: {
          topic: action,
          plate_number: this.qryCarNum,
          channel_path: this.channel_path || -1
        }
      }
      await mqtt.subscribe(topic, options)
    },
    unsubscribe: function (action, callback) {
      // 订阅
      const topic = `/ypt/u_${this.sid}/${encodeURIComponent(
        this.qryCarNum
      )}`
      const options = {
        payload: {
          topic: action,
          plate_number: this.qryCarNum
        }
      }
      mqtt.unsubscribe(topic, options, function (err, granted) {
        if (err) {
          callback(err, granted)
          return
        }
        callback(null, granted)
      })
    },
    queryFQResult: function (topic, message, packet) {
      console.log('@@@@@queryFQResult1', message)
      //  this.pnulloutvisible = true
      const that = this
      const plateNumber = that.plateNumber.join('')
      if (decodeURIComponent(message.plate_number) === plateNumber) {
        that.$toast.clear()
        if (isNaN(Number(message.fee_value)) || message.fee_value == null) {
          if (/^N/.test(this.qryCarNum)) {
            localStorage.removeItem('vh_p_number')
          }
          return that.$toast(message.msg || '计算费用失败')
        }
        const localInfo = JSON.stringify(message)

        if (Number(message.fee_value) <= 0) {
          that.$toast('收费 0 元')
          if (message.vh_cate_id !== 1) {
            this.$store.commit(constant.keyEscapeVuex, message.escInfo)

            that.$router.push({
              path: '/payMoney',
              query: {
                plate_number: plateNumber,
                gross_price: message.gross_price,
                req_order_sn: message.req_order_sn,
                start_time: message.start_time,
                end_time: message.end_time,
                sign: message.sign,
                localInfo: localInfo,
                pay_channel_platform: message.pay_channel_platform,
                receive_timestamp: new Date().getTime()
              }
            })
          }

          // let channelPath = this.$Storejs.getItem('channelPath')
          return
        }
        this.$store.commit(constant.keyEscapeVuex, message.escInfo)
        that.$router.push({
          path: '/payMoney',
          query: {
            plate_number: plateNumber,
            gross_price: message.gross_price,
            req_order_sn: message.req_order_sn,
            start_time: message.start_time,
            end_time: message.end_time,
            sign: message.sign,
            localInfo: localInfo,
            pay_channel_platform: message.pay_channel_platform,
            receive_timestamp: new Date().getTime()
          }
        })
      }
    },
    getTZ: function (topic, message, packet) {
      const plateNumber = this.plateNumber.join('')
      if (decodeURIComponent(message.plate_number) === plateNumber) {
        if (message.topic === 'tz') {
          if (message.state === 2) {
            // localStorage.removeItem('vh_p_number')
            this.$toast('支付成功')
          } else {
            this.$toast('支付完成')
          }
          this.$router.go(-1)
        }
      }
    },
    get_pnull_0_paid: function (topic, message, packet) {
      const channelPath = this.channelPath
      const that = this
      if (
        channelPath > 0 &&
        !message.is_done_open &&
        /^N.{7}/.test(decodeURIComponent(message.plate_number))
      ) {
        that.open_gate_0_fee({
          sid: that.sid,
          plate_number: message.plate_number,
          fee_value: message.fee_value,
          channel_path: channelPath,
          is_done_open: false
        })
      }
    },
    refreshHistoryNum (carNum) {
      if (carNum.length === 7 || carNum.length === 8) {
        const arrOld = this.historyNumList
        const arrNew = []
        arrNew.push(carNum, ...arrOld)
        // 去重
        const qcArr = [...new Set(arrNew)]
        // 最大四个
        if (qcArr.length === 5) {
          qcArr.splice(qcArr.length - 1, 1)
        }
        this.historyNumList = qcArr
        // 保存vuex
        this.$store.commit(constant.keyQueryLogHistoryVuex, this.historyNumList)
      }
    },
    // 查询通道车回调
    get_pnumber_by_out_channel_id: function (topic, message, packet) {
      if (message.topic === 'get_pnumber_by_out_channel_id') {
        if (message.vh_p_number) {
          this.refreshHistoryNum(message.vh_p_number)
          this.refreshPlateNumberFromChannel(message)
          // this.qryCarNum = plateNumber
        } else {
          const vh_p_number = localStorage.getItem('vh_p_number')
          if (vh_p_number) {
            this.refreshHistoryNum(vh_p_number)
            this.refreshPlateNumberFromChannel({
              vh_p_number
            })
            // this.qryCarNum = plateNumber
          } else {
            this.$toast(message.msg || '出口没有车辆')
          }
        }
      }
    },
    clearHistoryAndInput () {
      this.newFlag = false
      this.input_index = 0
      this.plateNumber = []
      this.qryCarNum = ''
    },
    async refreshPlateNumberFromChannel (data) {
      if (Object.keys(data).length > 0) {
        const plateNumber = data.vh_p_number || data.vh_pnull_number
        const temp = []
        for (let i = 0; i < 8; i++) {
          temp.push(plateNumber[i] || '')
        }
        if (temp.length === 8) {
          this.newFlag = true
        } else {
          this.newFlag = false
        }
        this.input_index = temp.length - 1
        this.plateNumber = temp
        this.qryCarNum = plateNumber

        // await this.queryData()
        this.queryData()
        // 更新最后一个车牌 index
      }
    },
    open_gate_0_fee (json, cb) {
      axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.vh_inout_out_open_gate,
        method: 'POST',
        data: {
          sid: this.sid,
          plate_number: json.plate_number,
          channel_path: json.channel_path,
          fee_value: json.fee_value,
          is_done_open: json.is_done_open,
          version: 'v2'
        },
        header: {
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          const d = res && res.data
          if (d.code !== common.resCode.OK) {
            Toast.fail('开闸失败！')
            typeof cb === 'function' && cb(null)
          } else {
            // eslint-disable-next-line standard/no-callback-literal
            typeof cb === 'function' && cb('开闸失败！')
          }
        })
        .catch((e) => {
          this.$toast('网络错误，开闸失败！')
          // eslint-disable-next-line standard/no-callback-literal
          typeof cb === 'function' && cb('网络错误，开闸失败！')
        })
    },
    async get_coupon (plateNumber, visible) {
      var para = {
        v: '1.0',
        app_key: 'd7ca0abc-6b4b-44e4-bc71-7fd329ce2f7a',
        app_pass: '17f6e649-d26f-488f-b03b-5676aa3be362',
        sid: this.sid,
        vh_p_number: plateNumber,
        page_no: 1,
        page_size: 1000,
        status: 1
      }
      var res = await axios({
        url: common.codeUrl + 'enterprise.coupon.list_by_vh',
        method: 'POST',
        data: para,
        header: {
          'content-type': 'application/json' // 默认值
        }
      })
      if (res.data.code && res.data.code === 10000) {
        if (res.data.list && res.data.list.length) {
          this.list = res.data.list
          if (visible) {
            this.$toast.clear()
            this.visible = true
          }
        }
      }
      return res
    },
    sendFeeHttpRequest (plateNumber, couponID) {
      const data = {
        sid: this.sid,
        plate_number: plateNumber,
        channel_path: this.channelPath ? this.channelPath : -1,
        version: 'v2'
      }
      if (couponID) {
        data.discount_coupon_ids = [couponID]
      }
      return axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.queryPrice,
        method: 'POST',
        data: data,
        header: {
          'content-type': 'application/json' // 默认值
        }
      })
    },
    bindFocus (index) {
      if (!this.showKeyboard) {
        this.showKeyboard = true
        this.input_index = Number(index)
      } else {
        this.input_index = Number(index)
      }
    },
    setNumber (data) {
      if (data[7] === '') {
        this.newFlag = false
      } else {
        this.newFlag = true
      }
    },
    setIndex (data) {
    },
    clickHistoryNum (data) {
      const plateNum = data
      // 如果点击的车牌号和输入框的不一样 则填入  否则 什么也不做
      const inpNum = this.plateNumber.join('')
      if (plateNum === inpNum) {
        return
      }
      const nowArr = plateNum.split('')

      const bakArr = JSON.parse(JSON.stringify(this.plateNumberBak))
      for (let i = 0; i < nowArr.length; i++) {
        if (nowArr[i] !== '') {
          bakArr[i] = nowArr[i]
        }
      }
      this.plateNumber = bakArr
      this.setNumber(this.plateNumber)
    },
    doCloseAll () {
      if (common.getUserAgent() === 'wx') {
        this.closeWindowWX()
      } else if (common.getUserAgent() === 'zfb') {
        this.closeWindowZfb()
      }
    },

    closeWindowWX () {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', close(), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', close())
          document.attachEvent('onWeixinJSBridgeReady', close())
        }
      } else {
        close()
      }

      function close () {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.call('closeWindow')
      }
    },
    closeWindowZfb () {
      if (window.AlipayJSBridge) {
        close()
      } else {
        if (document.addEventListener) {
          document.addEventListener('AlipayJSBridgeReady', close(), false)
        } else if (document.attachEvent) {
          document.attachEvent('AlipayJSBridgeReady', close())
        }
      }

      function close () {
        // eslint-disable-next-line no-undef
        AlipayJSBridge.call('closeWebview')
      }
    },
    checkAuthWhenOpenMenu () {
      // 需要授权登录且绑定手机号才可访问
      const authFlag = util.isHaveAuthAccount()
      if (!authFlag) {
        // Toast.fail('请先授权登录')
        this.showNotAuthPhoneType = 1
        this.showNotAuthPhone = true
        return false
      }
      const phone = util.isHaveBindPhone()
      // console.log('最终判断authFlag是否绑定手机号 ', phone)
      if (!phone) {
        // Toast.fail('请先绑定手机号')
        // 打开手机号弹窗
        // this.show_bind_phone = true
        this.showNotAuthPhoneType = 2
        this.showNotAuthPhone = true
        return false
      }
      return true
    },
    openMenu (item) {
      this.wantToPath = item.path
      // todo 假设7代表我的（不需要验证即可访问的页面）
      if (item.path !== '/user/my') {
        // TODO本地开发需要注释；正式发布时需要取消注释
        const flag = this.checkAuthWhenOpenMenu()
        if (flag) {
          this.$router.push(item.path)
        }
      } else {
        // 访问‘我的’菜单
        const authFlag = util.isHaveAuthAccount()
        if (!authFlag) {
          this.showNotAuthPhoneType = 1
          this.showNotAuthPhone = true
          return
        }
        this.$router.push(item.path)
      }
    }

  },
  // 清除state数据
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
    // const para = [constant.keyQueryLogHistoryVuex]
    // this.$store.commit(constant.keyClearAllState, para)
  }
}
</script>
<style lang="scss" scoped>
.home_box {
  // margin-top: 3.75rem;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // height: 100vh;
  // overflow-y: hidden;
  .blue-background {
    height: 2rem;
    // background-color: #74b0f9;

    background: linear-gradient(
      to bottom,
      #3ca2f4 0%,
      #3ca2f4 100%
    ); /* 使用 linear-gradient 设置蓝色背景 */
  }

  .white-background {
    // height: 0.52rem;
    flex: 2;
    background-color: #f0f2f5;
  }
  .home_content_box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    display: flex;
    flex-direction: column;

    // top: 60px;

    // flex: 0;
    header {
      padding: 0.16rem;
      // background-color: #74b0f9;
      display: flex;
      align-items: center;
      .parkSiteImg {
        display: flex;
        width: 0.64rem;
        height: 0.64rem;
        margin-left: 0.16rem;
        border: 0.01px solid #fff;
        .park-pic {
          width: 0.64rem;
          height: 0.64rem;
          background: #fff;
          object-fit: cover;
        }
      }
      .parkSiteText {
        font-size: 0.16rem;
        text-align: left;
        color: #fff;
        margin-left: 0.16rem;
        // line-height: 0.28rem;
        height: 0.66rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    main {
      margin-bottom: 0.16rem;
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      .plate_num_box {
        // height: 12.5rem;
        background-color: #fff;
        box-shadow: 0px 0.01rem 0.05rem 0px rgba(68, 112, 252, 0.37);
        background-color: rgba(253, 254, 255, 1);
        border-radius: 0.08rem;
        margin-left: 0.01rem;
        display: flex;
        flex-direction: column;
        // padding: 0 0 1.0625rem;
        padding: 0.08rem;
        .plate_num_box_tit {
          font-size: 0.16rem;
          text-align: left;
          font-weight: bolder;
          margin-top: 0.25rem;
          margin-bottom: 0.21rem;
          margin-left: 0.1rem;
        }
        .plate_num_box_input {
          width: 3.24rem;
          margin-top: 0.11rem;
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          padding: 0 0.08rem 0 0.08rem;
          .plate_wrap {
            width: 0.34rem;
            height: 0.44rem;
            background-color: rgba(233, 238, 255, 1);
            display: flex;
            align-items: center;
            text-align: center;
            .section-dashed {
              height: 0.44rem;
              border: 0.01rem solid #1989fa;
            }

            .plate_wrap_input {
              height: 0.44rem;
              width: 0.34rem;
              text-align: center;
              font-size: 0.16rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .new_vh {
                overflow-wrap: break-word;
                color: rgba(0, 0, 0, 1);
                font-size: 0.1rem;
                font-family: PingFangSC-Regular;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 0.14rem;
                margin: 0.01rem 0 0 -0.01rem;
              }
            }
            .new_input {
              background-color: rgba(233, 255, 235, 1);
              img {
                width: 0.18rem;
                height: 0.17rem;
                margin: 0 0.06rem 0 0.05rem;
              }
            }
          }
        }
        .history {
          margin-top: 0.16rem;
          display: flex;
          justify-content: flex-start;
          // flex-wrap: wrap;
          align-items: center;
          .historyIcon {
            margin-left: 0.08rem;
            margin-bottom: 0.08rem;
            font-size: 0.1rem;
          }
          .historyItem {
            background-color: #f4f4f4;
            padding: 0.05rem 0.09rem;
            border-radius: 0.12rem;
            font-size: 0.1rem;
            color: #333;

            margin-right: 0.04rem;
            margin-left: 0.08rem;
            margin-bottom: 0.08rem;
          }
        }
        .query_btn {
          background-color: rgba(68, 112, 252, 1);
          border-radius: 0.04rem;
          margin-top: 0.08rem;
          display: flex;
          flex-direction: column;
          padding: 0.08rem 1.21rem 0.08rem 1.21rem;
          .query_btn_text {
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 0.16rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.22rem;
          }
        }
      }
      .common_box {
        background-color: #fff;
        box-shadow: 0px 0.01rem 0.05rem 0px rgba(68, 112, 252, 0.37);
        border-radius: 0.08rem;
        display: flex;
        flex-wrap: wrap;
        padding: 0.08rem;
        margin-top: 0.2rem;
        .banner {
          margin-top: 0;
        }
      }
      .menuFooterBox {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .menu_box {
        .menu_wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 20%;
          margin-top: 0.08rem;
          margin-bottom: 0.08rem;

          // margin-bottom: 10px;
          // 一行五个 一行以内 上下间距10px  大于一行时  第一行的下间距和第二行的上间距 加起来为10 保持对称 以此类推

          .menu_img {
            width: 0.4rem;
            height: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 0.1rem;
            .iconfont {
              font-size: 0.2rem;
            }

            // width: 3.75rem;
            // height: 100%;
            // img {
            //   width: 100%;
            //   height: 100%;
            // }
          }
          .menu_title {
            font-size: 0.1rem;
            color: #333;
            margin-top: 0.06rem;
          }
        }
      }
      .merchant_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.2rem;
        .menu_box {
          width: 43%;

          background-color: #fff;
          box-shadow: 0px 0.01rem 0.05rem 0px rgba(68, 112, 252, 0.37);
          border-radius: 0.08rem;
          display: flex;
          flex-wrap: wrap;
          padding: 0.08rem;
          .menu_img {
            width: 0.4rem;
            height: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 0.1rem;
            background: linear-gradient(to bottom, #ffe700 0%, #ffb200 100%);
            .iconfont {
              font-size: 0.2rem;
            }
          }
          .menu_shanghu {
            background: linear-gradient(to bottom, #ffe700 0%, #ffb200 100%);
          }
          .menu_zhangdan {
            background: linear-gradient(to bottom, #fcff6d 0%, #cca100 100%);
          }
          .menu_title {
            font-size: 0.16rem;
            line-height: 0.4rem;
            color: #333;
            margin-left: 0.12rem;
            // padding: 0.04rem 0.08rem;
          }
        }
      }
      .footer {
        // margin-bottom: 0.16rem;
        // position: fixed;
        // bottom: 0.16rem;
        // .AD {
        //   color: #333;
        //   padding: 0.04rem 0.08rem;
        //   font-size: 0.16rem;
        // }
      }
    }
  }
}
</style>
